import React from 'react';

import CardJob from '@/components/Card/Job';
import CardTender from '@/components/Card/Tender';
import AppCarousel from '@/components/Carousel';

import { TypeFindJob } from '@/helpers/common.constant';
import { useSearchJob } from '@/queries/useSearchJob';

type JobCarouselProp = {
  type: TypeFindJob;
};

const JobCarousel = ({ type }: JobCarouselProp) => {
  const {
    query: { data: { data } = {} },
  } = useSearchJob({ type });

  if (!data?.length) {
    return null;
  }
  return (
    <AppCarousel slideCount={data?.length}>
      {data?.map((p: any) => (
        <div key={p.id} className='h-full p-4'>
          {type === TypeFindJob._PROJECT ? (
            <CardJob job={p} />
          ) : (
            <CardTender tender={p} />
          )}
        </div>
      ))}
    </AppCarousel>
  );
};

export default JobCarousel;
